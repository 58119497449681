"use client";

import { defaultHead } from "next/head";
import React, { useEffect, useState } from "react";
import styles from "./loginDialog.module.scss";
import { FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import LoginByEmail from "../ByEmail/ByEmail";
import LoginByPhone from "../ByPhone/ByPhone";
// import "./state.css";
import Signup from "../signup/signup";
import VerificationCode from "../verificationCode/verificationCode";
import VerificationWay from "../verificationWay/verificationWay";
import { useRouter, useSearchParams } from "next/navigation";
import { useLocation } from "@hooks/useLocation";
import Cookies from "js-cookie";
import { sendGTMEvent } from "@next/third-parties/google";

export default function LoginDialog({ CloseLogin= () => {}, SuccessClose= ()=> {}, isRoute = false, eventID=""}) {
  const [ByEmail, setByEmail] = useState(true);
  const [data, setData] = useState();
  const [dataVerify, setDataVerify] = useState();
  const [action, setAction] = useState(""); 
  const [startingAccount, setStartingAccount] = useState("");
  const searchParams = useSearchParams();
  const router = useRouter();
  const utm_source = searchParams.get('utm_source'); 
  const {location, countryCode} = useLocation();
  async function fetchIP() {
    const res = await fetch('/api/get-ip');
    const data = await res.json();
    console.log('Client IP:', data.ip);
  }
// console.log(location);

  useEffect(()=>{
    fetchIP();
  },[])
  const handleLoginSuccess = (data, token) => {
console.log(data);

    Cookies.set(
      "user",
      JSON.stringify({
        id: data.uid,
        email: data.email,
        idToken: token,
        refreshToken: token,
        fname: data.firstName,
        lastName: data.lastName,
        country: data.address,
        phoneNumber: data.phoneNumber,
        gender: data.gender,
        dob: data.dob,
        updatedFields: data.updatedFields,
        username: data.userName,
        isPhoneVerified: data.isPhoneVerified,
        isEmailVerified: data.isEmailVerified,
      } ),
      {
        expires: 365, // Keep cookie for 1 year
        secure: true, // Optional: only send over HTTPS
        sameSite: "Lax", // Optional: protects against CSRF in most cases
      }
    );
    sendGTMEvent({ event: 'Sign In', value: 'Email' })
    Cookies.set("token", JSON.stringify(token),   {
      expires: 365, // Keep cookie for 1 year
      secure: true, // Optional: only send over HTTPS
      sameSite: "Lax", // Optional: protects against CSRF in most cases
    });
    Cookies.set(
      "tokens",
      JSON.stringify({
        idToken: token,
        refreshToken: token,
      }),   {
        expires: 365, // Keep cookie for 1 year
        secure: true, // Optional: only send over HTTPS
        sameSite: "Lax", // Optional: protects against CSRF in most cases
      }
    );
    if (utm_source) router.push(`/payment/${eventID}?utm_source=${utm_source}`);
    else router.push(`/payment/${eventID}`)
    
  };

  function handleRetrieveData(data, googleOrApple = false) {
    console.log(data);
    if(data.nextAction !== "COMPLETE-PROFILE-DATA") setData(data);
    if(data.nextAction === "COMPLETE-PROFILE-DATA") {
      if(googleOrApple) setData(data)
      setDataVerify(data);
    }
    if(data.nextAction === "VALIDATE-OTP") {
      if(data.method === "EMAIL") {
        setAction(data.nextAction);
      }
      if(data.method === "PHONE") {
        setAction(data.nextAction);
      }
    }
    console.log(data);
    if(data.nextAction === "SELECT-PHONE-METHOD") {
      if(data.method === "PHONE") {
        setAction(data.nextAction);
      }
      if(data.method === "EMAIL") {
        setAction(data.nextAction);
      }
    }
    if(data.nextAction === "COMPLETE-PROFILE-DATA")
      setAction(data.nextAction);
    if(data.nextAction === "CONTINUE") 
      if(isRoute) handleLoginSuccess(data.userData, data.token);
      else SuccessClose(data.userData, data.token);
  }

  function handleResendBack (email) {
    setData(null);
    setAction("")
    setByEmail(email);
  }
  return (
    <>
      <div className={styles.overlay}></div>
    {!isRoute &&  <button className={styles.closeButton} onClick={CloseLogin}>
        x
      </button>}
      {action === "" && <div className={isRoute? `${styles.container} ${styles.route}` : `${styles.container}`} >
        <div className={styles.header}>
          <div className={styles.title}>Login / Sign up</div>
          <div className={styles.desc}>Get access to the hottest events</div>
        </div>
        <div className={styles.way}>
          <button
            className={ByEmail ? {} : styles.selected}
            onClick={() => setByEmail(false)}
          >
            Phone Number
          </button>
          <button
            className={!ByEmail ? {} : styles.selected}
            onClick={() => setByEmail(true)}
          >
            Email
          </button>
        </div>
        {ByEmail ? (
          <LoginByEmail handleRetrieveData={handleRetrieveData} setStartingAccount={setStartingAccount}/>
        ) : (
          <LoginByPhone handleRetrieveData={handleRetrieveData} setStartingAccount={setStartingAccount} location={location} countryCode={countryCode}/>
        )}
      </div>}
      {action === "COMPLETE-PROFILE-DATA" && <Signup data={data} handleRetrieveData={handleRetrieveData} dataVerify={dataVerify} isRoute={isRoute}/>}
      {action === "VALIDATE-OTP" && <VerificationCode data={data} handleRetrieveData={handleRetrieveData} handleResendBack={handleResendBack} startingAccount={startingAccount} isRoute={isRoute}/>}
      {action === "SELECT-PHONE-METHOD" && <VerificationWay receivedData={data} phone={data.to} handleRetrieveData={handleRetrieveData} isRoute={isRoute}/>}
    </>
  );
}
